import { createTextVNode as _createTextVNode, createVNode as _createVNode } from "vue";
export default {
  name: "logDatas",
  data() {
    return {
      logColumns: [{
        label: '操作人',
        prop: 'operator'
      }, {
        label: '操作模块',
        prop: 'model'
      }, {
        label: '操作类型',
        prop: 'name'
      }, {
        label: '请求IP',
        prop: 'remoteIp'
      }, {
        label: '执行结果',
        customRender: props => {
          if (props.row.success) {
            return _createVNode("span", null, [_createVNode("span", {
              "class": "dot dot-success"
            }, null), _createVNode("span", {
              "style": "margin-left: 8px;"
            }, [_createTextVNode("\u6210\u529F")])]);
          } else {
            return _createVNode("span", null, [_createVNode("span", {
              "class": "dot dot-fail"
            }, null), _createVNode("span", {
              "style": "margin-left: 8px;"
            }, [_createTextVNode("\u5931\u8D25")])]);
          }
        },
        width: '110'
      }, {
        label: '操作时间',
        prop: 'beginTime'
      }, {
        label: '耗时（ms）',
        prop: 'consume',
        width: '110'
      }, {
        label: '备注',
        prop: 'exceptionDesc'
      }],
      searchItems: [{
        prop: 'model',
        label: '模块'
      }, {
        prop: 'name',
        label: '日志名称'
      }, {
        prop: 'status',
        label: '执行结果',
        type: 'select',
        items: [{
          name: "成功",
          value: 1
        }, {
          name: "失败",
          value: 0
        }],
        itemProp: {
          label: 'name',
          value: 'value'
        }
      }, {
        prop: 'time',
        propItems: ['beginTime', 'endTime'],
        label: '时间范围',
        type: 'date',
        dateType: 'daterange',
        valueFormat: 'YYYY-MM-DD',
        defaultValue: [new Date().format('yyyy-MM-dd'), new Date().format('yyyy-MM-dd')],
        shortcuts: this.defaultDateShortcuts()
      }]
    };
  },
  methods: {
    defaultDateShortcuts() {
      return [{
        text: '今日',
        value: () => {
          const end = new Date();
          const start = new Date();
          return [start, end];
        }
      }, {
        text: '昨日',
        value: () => {
          const end = new Date();
          const start = new Date();
          start.setTime(start.getTime() - 3600 * 1000 * 24);
          end.setTime(end.getTime() - 3600 * 1000 * 24);
          return [start, end];
        }
      }, {
        text: '本月',
        value: () => {
          const end = new Date();
          const start = new Date();
          start.setTime(new Date(new Date().getFullYear(), new Date().getMonth(), 1));
          return [start, end];
        }
      }, {
        text: '上月',
        value: () => {
          const end = new Date();
          const start = new Date();
          let day = new Date(start.getFullYear(), start.getMonth(), 0).getDate();
          start.setTime(new Date(new Date().getFullYear(), new Date().getMonth() - 1, 1));
          end.setTime(new Date(new Date().getFullYear(), new Date().getMonth() - 1, day));
          return [start, end];
        }
      }, {
        text: '最近一周',
        value: () => {
          const end = new Date();
          const start = new Date();
          start.setTime(start.getTime() - 3600 * 1000 * 24 * 7);
          return [start, end];
        }
      }, {
        text: '最近一个月',
        value: () => {
          const end = new Date();
          const start = new Date();
          start.setTime(start.getTime() - 3600 * 1000 * 24 * 30);
          return [start, end];
        }
      }, {
        text: '最近二个月',
        value: () => {
          const end = new Date();
          const start = new Date();
          start.setTime(start.getTime() - 3600 * 1000 * 24 * 30 * 2);
          return [start, end];
        }
      }, {
        text: '最近三个月',
        value: () => {
          const end = new Date();
          const start = new Date();
          start.setTime(start.getTime() - 3600 * 1000 * 24 * 30 * 3);
          return [start, end];
        }
      }];
    }
  }
};